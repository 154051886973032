import React from 'react';
import './trend.css';

const TrendingClothes = () => {
    return (
        <div>
             <section className="hero">
      <div className="hero-content">
        <h1>Explore the Latest Men's Fashion Trends</h1>
        <p>Stay ahead of the fashion curve with trending styles, outfits, and more!</p>
        <button className="shop-now">Shop Now</button>
      </div>
    </section>
            {/* Trending Clothes Section */}
            <section className="trending-clothes"> 
                <h2>Trending Clothes</h2>
                <div className="clothes-container">
                    <div className="clothes-item">
                        <img src="https://myraymond.com/cdn/shop/files/CJSQ00638-B7-1.jpg?v=1716968474" alt="Stylish Suits" />
                        <p>Stylish Suits</p>
                    </div>
                    <div className="clothes-item">
                        <img src="https://myraymond.com/cdn/shop/files/CJSQ00638-B7-1.jpg?v=1716968474" alt="Casual Wear" />
                        <p>Casual Wear</p>
                    </div>
                    <div className="clothes-item">
                        <img src="https://myraymond.com/cdn/shop/files/CJSQ00638-B7-1.jpg?v=1716968474" alt="Formal Shirts" />
                        <p>Formal Shirts</p>
                    </div>
                </div>
            </section>

            {/* Types of Clothes Section */}
            <section className="types-of-clothes">
                <h2>Types of Clothes</h2>
                <div className="clothes-container">
                    <div className="clothes-item">
                        <img src="https://myraymond.com/cdn/shop/files/CJSQ00638-B7-1.jpg?v=1716968474" alt="Suits" />
                        <p>Suits</p>
                    </div>
                    <div className="clothes-item">
                        <img src="https://myraymond.com/cdn/shop/files/CJSQ00638-B7-1.jpg?v=1716968474" alt="T-shirts" />
                        <p>T-shirts</p>
                    </div>
                    <div className="clothes-item">
                        <img src="https://myraymond.com/cdn/shop/files/CJSQ00638-B7-1.jpg?v=1716968474" alt="Jeans" />
                        <p>Jeans</p>
                    </div>
                </div>
            </section>

            {/* New Trends Section */}
            <section className="new-trends">
                <h2>New Trends</h2>
                <div className="clothes-container">
                    <div className="clothes-item">
                        <img src="https://myraymond.com/cdn/shop/files/CJSQ00638-B7-1.jpg?v=1716968474" alt="Bold Patterns" />
                        <p>Bold Patterns</p>
                    </div>
                    <div className="clothes-item">
                        <img src="https://myraymond.com/cdn/shop/files/CJSQ00638-B7-1.jpg?v=1716968474" alt="Minimalist Look" />
                        <p>Minimalist Look</p>
                    </div>
                    <div className="clothes-item">
                        <img src="https://myraymond.com/cdn/shop/files/CJSQ00638-B7-1.jpg?v=1716968474" alt="Earthy Tones" />
                        <p>Earthy Tones</p>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default TrendingClothes;
