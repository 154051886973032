import React, { useState, useEffect } from 'react';

const AddressBook = () => {
  const [addresses, setAddresses] = useState(() => {
    const savedAddresses = localStorage.getItem("addresses");
    return savedAddresses ? JSON.parse(savedAddresses) : [];
  });
  const [defaultAddressIndex, setDefaultAddressIndex] = useState(
    () => localStorage.getItem("defaultAddressIndex") || 0
  );
  const [showAllAddresses, setShowAllAddresses] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [currentAddress, setCurrentAddress] = useState({
    name: '',
    address: '',
    city: '',
    state: '',
    zip: ''
  });
  const [editIndex, setEditIndex] = useState(null);

  useEffect(() => {
    localStorage.setItem("addresses", JSON.stringify(addresses));
    if (defaultAddressIndex !== null) {
      localStorage.setItem("defaultAddressIndex", defaultAddressIndex);
    }
  }, [addresses, defaultAddressIndex]);

  const renderAddressList = () => {
    return addresses.length === 0 ? (
      <p>No addresses saved.</p>
    ) : (
      addresses.map((address, index) => (
        // Always render the default address regardless of showAllAddresses state
        (index === defaultAddressIndex || showAllAddresses) && (
          <div className="address-item" key={index}>
            <p><strong>{address.name}</strong> {defaultAddressIndex == index ? "(Default)" : ""}</p>
            <p>{address.address}, {address.city}, {address.state}, {address.zip}</p>
            <button onClick={() => loadAddressForEdit(index)}>Edit</button>
            {/* Hide the "Set as Default" button if this address is already the default */}
            {defaultAddressIndex !== index && (
              <button onClick={() => setDefaultAddress(index)}>Set as Default</button>
            )}
            <button onClick={() => deleteAddress(index)}>Delete</button>
          </div>
        )
      ))
    );
  };

  const loadAddressForEdit = (index) => {
    const address = addresses[index];
    setCurrentAddress(address);
    setEditIndex(index);
    setIsFormVisible(true);
  };

  const deleteAddress = (index) => {
    const updatedAddresses = addresses.filter((_, i) => i !== index);
    setAddresses(updatedAddresses);
    if (defaultAddressIndex == index) {
      setDefaultAddressIndex(null);
      localStorage.removeItem("defaultAddressIndex");
    }
  };

  const setDefaultAddress = (index) => {
    setDefaultAddressIndex(index);
    setShowAllAddresses(false); // Hide other addresses when default is set
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const updatedAddresses = [...addresses];
    if (editIndex === null) {
      updatedAddresses.push(currentAddress);
    } else {
      updatedAddresses[editIndex] = currentAddress;
    }
    setAddresses(updatedAddresses);
    setIsFormVisible(false);
    setCurrentAddress({ name: '', address: '', city: '', state: '', zip: '' });
    setEditIndex(null);
  };

  return (
    <div id="addresses" className="tab-content">
      <p className='address-summary'>Delivery Address</p>
      <button onClick={() => setShowAllAddresses(!showAllAddresses)}>
        {showAllAddresses ? "Hide" : "Show All Addresses"}
      </button>
      <div className="address-list">
        {renderAddressList()}
      </div>
      <button onClick={() => setIsFormVisible(true)}>Add New Address</button>
      
      {isFormVisible && (
        <div className="modal">
          <div className="modal-content">
            <button onClick={() => setIsFormVisible(false)}>&times;</button>
            <h2>{editIndex === null ? "Add New Address" : "Edit Address"}</h2>
            <form onSubmit={handleFormSubmit}>
              <label>Full Name:</label>
              <input
                type="text"
                value={currentAddress.name}
                onChange={(e) =>
                  setCurrentAddress({ ...currentAddress, name: e.target.value })
                }
                required
              />
              <label>Street Address:</label>
              <input
                type="text"
                value={currentAddress.address}
                onChange={(e) =>
                  setCurrentAddress({ ...currentAddress, address: e.target.value })
                }
                required
              />
              <label>City:</label>
              <input
                type="text"
                value={currentAddress.city}
                onChange={(e) =>
                  setCurrentAddress({ ...currentAddress, city: e.target.value })
                }
                required
              />
              <label>State:</label>
              <input
                type="text"
                value={currentAddress.state}
                onChange={(e) =>
                  setCurrentAddress({ ...currentAddress, state: e.target.value })
                }
                required
              />
              <label>ZIP Code:</label>
              <input
                type="text"
                value={currentAddress.zip}
                onChange={(e) =>
                  setCurrentAddress({ ...currentAddress, zip: e.target.value })
                }
                required
              />
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddressBook;
