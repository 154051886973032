import React, { useEffect, useRef, useState } from 'react';
import './Mirror.css';
import Header from './Header';

const CameraAccess = () => {
    const [isCameraOn, setIsCameraOn] = useState(false);
    const videoRef = useRef(null);
    const [stream, setStream] = useState(null);

    // Function to start the camera
    const startCamera = async () => {
        try {
            const localStream = await navigator.mediaDevices.getUserMedia({ video: true });
            setStream(localStream);
            videoRef.current.srcObject = localStream;
            setIsCameraOn(true);
        } catch (error) {
            console.error('Error accessing the camera:', error);
            alert('Unable to access the camera. Error: ' + error.message);
        }
    };

    // Function to stop the camera
    const stopCamera = () => {
        if (stream) {
            // Stop all video tracks to release the camera
            stream.getTracks().forEach(track => track.stop());
            videoRef.current.srcObject = null;
            setIsCameraOn(false);
        }
    };

    // Toggle camera function
    const toggleCamera = () => {
        if (isCameraOn) {
            stopCamera();
        } else {
            startCamera();
        }
    };

    // Cleanup the stream when the component unmounts
    useEffect(() => {
        return () => {
            if (stream) {
                stopCamera();
            }
        };
    }, [stream]);

    return (
        <div><Header></Header>
        <div className="camera-container">
            <h2>Camera Access</h2>
            <video ref={videoRef} autoPlay style={{ display: isCameraOn ? 'block' : 'none' }} />
            <button onClick={toggleCamera}>{isCameraOn ? 'Close Camera' : 'Open Camera'}</button>
        </div></div>
    );
};

export default CameraAccess;
