import React from "react";

const CollectionsSection = () => {
  return (
    <section className="collections-section">
      <div className="container">
        <h1>Our Collections</h1>
        <div className="collection-grid">
          {/* Product 1 */}
          <div className="collection-item">
            <img
              src="https://myraymond.com/cdn/shop/files/2._Casual_Shirt.jpg?v=1711091570"
              alt="Product 1"
            />
            <div className="text-overlay">
              <h2>Old Fashion</h2>
            </div>
          </div>
          
          {/* Product 2 */}
          <div className="collection-item">
            <img
              src="https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/m/q/r/xl-formal-shirt-metronaut-original-imah3g7mqnjcfytf.jpeg?q=70&crop=false"
              alt="Product 1"
            />
            <div className="text-overlay">
              <h2>Formal Shirt/Pant</h2>
            </div>
          </div>
          
          {/* Product 3 */}
          <div className="collection-item">
            <img
              src="https://myraymond.com/cdn/shop/files/4._Chions_and_Trouser.jpg?v=1711091569product2.jpg"
              alt="Product 2"
            />
            <div className="text-overlay">
              <h2>Polo Shirt</h2>
            </div>
          </div>
          
          {/* Product 4 */}
          <div className="collection-item">
            <img
              src="https://myraymond.com/cdn/shop/files/6._Suits_and_Blazers.jpg?v=1711091570"
              alt="Product 3"
            />
            <div className="text-overlay">
              <h2>Polo T-shirt</h2>
            </div>
          </div>
          
          {/* Add more products as needed */}
        </div>
      </div>
    </section>
  );
};

export default CollectionsSection;
