import React from "react";
const Footer = () => {
  return (
    <footer id="footer" className="footer">
      <div className="footer-container">

        {/* First Section */}
        <div className="sec1">
          <div className="footer-column">
            <h4>Company</h4>
            <ul>
              <li><a href="#">Contact Us</a></li>
              <li><a href="#">About Us</a></li>
              <li><a href="#">Careers</a></li>
            </ul>
          </div>

          <div className="footer-column">
            <h4>Help</h4>
            <ul>
              <li><a href="#">Payments</a></li>
              <li><a href="#">Shipping</a></li>
              <li><a href="#">Cancellation</a></li>
              <li><a href="#">FAQ</a></li>
            </ul>
          </div>

          <div className="footer-column">
            <h4>Policy</h4>
            <ul>
              <li><a href="#">Return Policy</a></li>
              <li><a href="#">Terms Of Use</a></li>
              <li><a href="#">Security</a></li>
              <li><a href="#">Privacy</a></li>
            </ul>
          </div>

          <div className="footer-column">
            <h4>Social</h4>
            <ul>
              <li><a href="#">Facebook</a></li>
              <li><a href="#">Twitter</a></li>
              <li><a href="#">YouTube</a></li>
            </ul>
          </div>
        </div>

        {/* Second Section: Mail and Office Address */}
        <div className="sec2">
          <div className="footer-column mail">
            <h4>Mail Us</h4>
            <address>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.<br />
              Ad culpa, ipsam porro ullam praesentium<br />
              eveniet impedit necessitatibus modi a iure.
            </address>
          </div>

          <div className="footer-column">
            <h4>Registered Office Address:</h4>
            <address>
              Lorem ipsum dolor sit.<br />
              Lorem ipsum dolor sit amet consectetur.<br />
              Lorem, ipsum dolor sit amet consectetur adipisicing.
            </address>
          </div>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="footer-bottom">
        <p>&copy; 2024 All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
