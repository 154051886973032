import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  return (   
    <header className="header">
      <div className="container">
        <nav className="navbar">
          <Link to="/" className="logo">BLAS BLUSH</Link>
          <div className="menu-icon" id="menu-icon">
            <i className="fas fa-bars"></i>
          </div>
          <ul className="nav-links" id="nav-links">
            <li className="dropdown"> <Link to="/">Home</Link> </li>
            <li className="dropdown">
              <Link to="#">Groom</Link>
              <ul className="dropdown-menu">
                <li>
                  <Link to="/TrendingClothes">Trend</Link>
                </li>
                
                <li>
                  <Link to="/Mirror">Mirror</Link>
                </li>
              </ul>
            </li>
            <li className="dropdown">
              <Link to="#">Company</Link>
              <ul className="dropdown-menu">
                <li><Link to="#footer">About</Link></li>
                <li><Link to="#footer">Contact</Link></li>
                <li><Link to="#">Career</Link></li>
              </ul>
            </li>
            <li className="dropdown">
              <Link to="#">Products</Link>
              <ul className="dropdown-menu">
                <li><Link to="#footer">Old Fashion</Link></li>
                <li><Link to="#footer">Formal Shirt/Pant</Link></li>
                <li><Link to="#">Polo Shirts</Link></li>
                <li><Link to="#">Polo T</Link></li>
              </ul>
            </li>
          </ul>
          <div className="user-section">
            <a href="#" className="user-icon">
              <span className="username"><i className="fa">&#xf007;</i></span>
            </a>
            <div className="user-dropdown">
              <ul>
                <li><Link to="/profile"><i className="fa">&#xf007;</i> My Profile</Link></li>
                <li><Link to="#"><i className='fas fa-shopping-bag'></i>Orders</Link></li>
                <li><Link to="#"><i className="fas fa-heart"></i> Wishlist</Link></li>
                <li><Link to="/cart"><i className="fa fa-shopping-cart"></i> Cart</Link></li>
                <li><Link to="#"><i className="fas fa-sign-out-alt"></i> Logout</Link></li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      
  
    </header>
  );
}

export default Header;
