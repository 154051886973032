import React from "react";
import { useNavigate } from 'react-router-dom';

const products = [
  {
    id: 1,
    link: "productpage.html",
    image: "https://myraymond.com/cdn/shop/collections/3._T-shirt.png?v=1710821824",
    title: "Light Grey Regular Fit Mens Jean",
    subtitle: "Sab Title",
    discountPrice: "Rs.299.00",
    mainPrice: "Rs.499",
    discount: "20% Off",
  },{
    id: 2,
    link: "productpage.html",
    image: "https://rukminim2.flixcart.com/image/832/832/xif0q/sweatshirt/h/b/r/s-aw18-rn-swtshrt-grey-navy-dark-maniac-original-imah2z3hhardze9b.jpeg?q=70&crop=false",
    title: "Men Full Sleeve Printed Sweatshirt",
    subtitle: "MANIAC",
    discountPrice: "Rs.399.00",
    mainPrice: "Rs.1499",
    discount: "73% Off",
  }, {
    id: 3,
    link: "productpage.html",
    image: "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/q/w/9/l-sc-sky-blue-maan-international-original-imah4grmzvsndjgj.jpeg?q=70&crop=false",
    title: "Men Regular Fit Solid Spread Collar Casual Shirt",
    subtitle: "Maan International",
    discountPrice: "Rs.788.00",
    mainPrice: "Rs.2499",
    discount: "68% Off",
  },
  {
    id: 4,
    link: "productpage.html",
    image: "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/k/5/a/50-whitediamond1pf-ramraj-cotton-original-imagyt4cze7anyng.jpeg?q=70&crop=false",
    title: "Men Regular Fit Solid Spread Collar Formal Shirt",
    subtitle: "Ramraj Cotton",
    discountPrice: "Rs.658.00",
    mainPrice: "Rs.999",
    discount: "40% Off",
  },
  {
    id: 5,
    link: "productpage.html",
    image: "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/4/h/5/xl-dpblack-woxen-original-imah3hhxckfzynfv.jpeg?q=70&crop=false",
    title: "Men Regular Fit Solid Spread Collar Casual Shirt",
    subtitle: "WOXEN",
    discountPrice: "Rs.355.00",
    mainPrice: "Rs.2099",
    discount: "83% Off",
  },
  {
    id:6,
    link: "productpage.html",
    image: "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/o/o/n/xl-ds-shirt1242-s-dark-age-original-imagujxtqja6shfa.jpeg?q=70&crop=false",
    title: "Men Slim Fit Checkered Slim Collar Casual Shirt#JustHere",
    subtitle: "Tanip",
    discountPrice: "Rs.219.00",
    mainPrice: "Rs.1399",
    discount: "84% Off",
  },
  {
    id: 7,
    link: "productpage.html",
    image: "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/j/5/y/xxl-lst-cnz-black-ethnic-trendz-original-imah3wswjf9hgrvh.jpeg?q=70&crop=false",
    title: "Men Regular Fit Solid Mandarin Collar Casual Shirt",
    subtitle: "RAHUL LOOK ",
    discountPrice: "Rs.269.00",
    mainPrice: "Rs.999",
    discount: "73% Off",
  },
  {
    id: 8,
    link: "productpage.html",
    image: "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/c/3/0/s-st2-vebnor-original-imah4y67hnf4q2fg.jpeg?q=70&crop=false",
    title: "Men Regular Fit Solid Spread Collar Casual Shirt#JustHere",
    subtitle: "VeBNoR",
    discountPrice: "Rs.299.00",
    mainPrice: "Rs.999",
    discount: "70% Off",
  }
];

const ProductSection = () => {
  const navigate = useNavigate();

  const addToCart = (product, redirectToCheckout = false) => {
    const existingCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];

    // Check if the product already exists in the cart
    const existingProductIndex = existingCartItems.findIndex(item => item.id === product.id);

    if (existingProductIndex !== -1) {
      // If product exists, increase its quantity
      existingCartItems[existingProductIndex].quantity += 1;
    } else {
      // If product does not exist, add it with a quantity of 1
      existingCartItems.push({ ...product, quantity: 1 });
    }

    // Update cart items in localStorage
    localStorage.setItem('cartItems', JSON.stringify(existingCartItems));

    if (redirectToCheckout) {
      localStorage.setItem('selectedProduct', JSON.stringify({ ...product, quantity: 1 }));
      navigate('/Checkout');
    } else {
      alert("Added to cart");
    }
  };

  return (
    <div className="product-section">
      {products.map((product) => (
        <div key={product.id} className="product">
          <a href={product.link} className="product-link">
            <img src={product.image} alt={product.title} className="product-image" />
            <h4 className="product-title">{product.title}</h4>
            <h3 className="sub-title">{product.subtitle}</h3>
            <div className="price">
              <h3 className="main-price">
                {product.mainPrice} <span className="d-price">{product.discountPrice}</span> <span className="s-discount">{product.discount}</span>
              </h3>
            </div>
          </a>
          <div className="button-group">
            <button className="cart-button add-to-cart-btn" onClick={() => addToCart(product)}>
              Add To Cart
            </button>
            <button className="cart-button add-to-cart-btn" onClick={() => addToCart(product, true)}>
              Buy Now
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProductSection;
