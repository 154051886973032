import React, { useState, useEffect } from 'react';
import AddressSection from "./AddressSection";
import CartItems from "./CartItems";
import PaymentSection from "./Paymentsection";
import Ordersummary from "./Ordersummary";
import './checkout.css';

    const App = () => {
  const [cartItems, setCartItems] = useState([]);

  useEffect(() => {
    // Load cart items from localStorage
    const savedItems = localStorage.getItem('cartItems');
    if (savedItems) setCartItems(JSON.parse(savedItems));
  }, []);

  const removeFromCart = (name) => {
    const updatedCart = cartItems.filter(item => item.name !== name);
    setCartItems(updatedCart);
    localStorage.setItem('cartItems', JSON.stringify(updatedCart));
  };

  return (
    <div>
      <div  className="checkout-container">
        <header><h2>Checkout</h2></header>
      <AddressSection/>
      <CartItems/>
      <PaymentSection/>
      </div >
      <div className="summary-container">
      <Ordersummary/>
      </div>
      
      
    </div>
  );
};

export default App;
