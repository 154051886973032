import React from "react";
import { Routes, Route, useLocation } from "react-router-dom"; 
import Header from './components/Header';
import Footer from './components/Footer';
import TrendingClothes from './components/TrendingClothes';
import ImageSlider from './components/ImageSlider';
import CollectionSection from './components/CollectionsSection';
import CategorySection from './components/CategorySection';
import ProductSection from "./components/ProductSection";
import Checkout from "./components/Checkout";
import Mirror from "./components/Mirror";
import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

function App() {
  const location = useLocation();
  
  const isSpecialPage = location.pathname === "/TrendingClothes" || location.pathname === "/Mirror";
  const isCheckoutPage = location.pathname === "/Checkout";
  
  return (
    <div>
      {!isCheckoutPage && <Header />}
      
      <Routes>
        <Route path="/TrendingClothes" element={<TrendingClothes />} />
        <Route path="/Mirror" element={<Mirror />} />
        <Route path="/Checkout" element={<Checkout />} />
      </Routes>

      {/* Show ImageSlider, CollectionSection, CategorySection, ProductSection only on main page */}
      {!isSpecialPage && !isCheckoutPage && (
        <>
          <ImageSlider />
          <CollectionSection />
          <CategorySection />
          <ProductSection />
        </>
      )}

      {/* Always show Footer except on Checkout page */}
      {!isCheckoutPage && <Footer />}
    </div>
  );
}

export default App;
