import React from 'react';

const PaymentSection = () => (
  <section className="payment-section">
    <h3>Payment Options</h3>
    <div className='option'>
    <div>
      <input type="radio" id="cod" name="payment" defaultChecked />
      <label htmlFor="cod">Cash on Delivery</label>
    </div>
    <div>
      <input type="radio" id="credit-card" name="payment" />
      <label htmlFor="credit-card">Credit Card</label>
    </div>
    <div>
      <input type="radio" id="debit-card" name="payment" />
      <label htmlFor="debit-card">Debit Card</label>
    </div>
    <div>
      <input type="radio" id="emi" name="payment" />
      <label htmlFor="emi">EMI</label>
    </div>
    <div>
      <input type="radio" id="upi" name="payment" />
      <label htmlFor="upi">UPI</label>
    </div>
    <div>
      <input type="radio" id="wallets" name="payment" />
      <label htmlFor="wallets">Wallets</label>
    </div>
    </div>
  </section>
);

export default PaymentSection;
