import React from "react";

const CategorySection = () => {
  return (
    <section className="category-section">
      <div className="category-section-inner">
        <div className="category-section-header">
          <p className="category-caption">Your Style, Your Choice</p>
          <h2 className="category-title">Classify Your Choice</h2>
        </div>
        
        <div className="category-collections">
          {/* Formal Shirts */}
          <div className="collection-item">
            <a href="/collections/formal-shirts">
              <div className="collection-media">
                <img
                  src="https://myraymond.com/cdn/shop/collections/shirt_08d8c6cc-594d-43d7-a68d-9208779c22f4.webp?v=1711690156"
                  alt="Formal Shirts"
                />
              </div>
              <div className="collection-text">
                <p>Formal Shirts</p>
              </div>
            </a>
          </div>

          {/* Casual Shirts */}
          <div className="collection-item">
            <a href="/collections/casual-shirts">
              <div className="collection-media">
                <img
                  src="https://myraymond.com/cdn/shop/collections/2._Casual_Shirt__Desktop.png?v=1711538063"
                  alt="Casual Shirts"
                />
              </div>
              <div className="collection-text">
                <p>Casual Shirts</p>
              </div>
            </a>
          </div>

          {/* Trousers & Chinos */}
          <div className="collection-item">
            <a href="/collections/trousers-chinos">
              <div className="collection-media">
                <img
                  src="https://myraymond.com/cdn/shop/collections/CMTR11850-K7_20_282_29.webp?v=1710856267"
                  alt="Trousers & Chinos"
                />
              </div>
              <div className="collection-text">
                <p>Trousers & Chinos</p>
              </div>
            </a>
          </div>

          {/* T-Shirts & Polo */}
          <div className="collection-item">
            <a href="/collections/t-shirts-polos">
              <div className="collection-media">
                <img
                  src="https://myraymond.com/cdn/shop/collections/3._T-shirt.png?v=1710821824"
                  alt="T-Shirts & Polo"
                />
              </div>
              <div className="collection-text">
                <p>T-Shirts & Polo</p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CategorySection;
